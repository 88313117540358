import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createSession } from "../services/api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loginFailed, setLoginFailed] = useState(null);
  const [loginFailedMessage, setLoginFailedMessage] = useState(null);

  useEffect(() => {
    const recoveredUser = localStorage.getItem("asu");

    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }

    setLoading(false);
  }, []);

  const login = async (username, password) => {
    try {
      const response = (await createSession(username, password)).data;
      const errorMessage = [
        "User not found",
        "Connection timed out",
        "Connection refused",
      ];

      if (errorMessage.includes(response)) {
        //console.log(response);
        throw response;
      }

      localStorage.setItem("reloadCount", 0);
      localStorage.setItem("asu", JSON.stringify(response));
      setUser(response);
      setLoginFailed(false);
      navigate("/");
    } catch (error) {
      setLoginFailed(true);
      setLoginFailedMessage(error);
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("asu");
    setUser(null);
    navigate("/login");
    console.log("logout");
    window.location.href = window.location.href;
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        loading,
        loginFailed,
        loginFailedMessage,
        login,
        logout,
        setLoginFailed,
        setLoginFailedMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
