import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { storeFCMToken } from "./services/api";

const firebaseConfig = {
  apiKey: "AIzaSyAh30TGBnwmnyZ3et007kxdYgF5yLm0208",
  authDomain: "sats-sjc.firebaseapp.com",
  projectId: "sats-sjc",
  storageBucket: "sats-sjc.appspot.com",
  messagingSenderId: "902275950270",
  appId: "1:902275950270:web:833bbb5deda362750e20ef",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = async (userId) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BED35-smWenMfUXeCE5aag2kkpTeqJiAZqVWqdWKmXRgECWmwih2UZtMdc2v1P4L6y_CTIzIyfnB6LYNzGyZ08Y",
    });
    if (currentToken) {
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      await storeFCMToken(userId, currentToken);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
