import moment from "moment";

const readingInterval = (dateTime) => {
  const readingDateTime = moment(dateTime);
  const currentDateTime = moment();
  const diff = moment(currentDateTime, "DD/MM/YYYY HH:mm:ss").diff(
    moment(readingDateTime, "DD/MM/YYYY HH:mm:ss")
  );
  return moment.duration(diff).asMinutes();
};

export const isDisconnected = (dateTime) =>
  readingInterval(dateTime) > 10 || dateTime == null;
