import axios from "axios";

const loggedUser = JSON.parse(localStorage.getItem("asu"));

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    common: {
      Auth: loggedUser ? `Bearer ${loggedUser.token}` : "",
    },
  },
});

//api.defaults.withCredentials = true;

export { api };

export const createSession = async (username, password) => {
  return api.post("/login.php", { withCredentials: false, username, password });
};

export const getPoints = async () => {
  return api.get("/points.php");
};

export const getAlarms = async () => {
  return api.get("/alarms.php");
};

export const getDetails = async (id, pointType, dateTime) => {
  return api.get("/details.php", { params: { id, pointType, dateTime } });
};

export const update = async (id, columns) => {
  return api.patch("/update.php", { id, columns });
};

export const updateConfig = async (id, receiveNotifications, timeInterval) => {
  const origin = navigator.userAgent;
  return api.patch("/update-config.php", {
    withCredentials: true,
    id,
    receiveNotifications,
    timeInterval,
    origin,
  });
};

export const storeFCMToken = (userId, token) => {
  const origin = navigator.userAgent;
  return api.post("/fcm-subscription.php", {
    withCredentials: true,
    userId,
    token,
    origin,
  });
};
