import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Chart = (props) => {
  const labels = props.chartData.map((label) => label.datetime.split(" ")[1]);
  const reading = props.chartData.map((data) => data.reading);

  const handleLabel = () => {
    switch (props.pointType) {
      case "1":
      case "11":
        return "Nível médio/hora (m.c.a.)";

      case "2":
        return "Status médio/hora (ligado/desligado)";

      case "3":
      case "4":
      case "9":
      case "10":
        return "Vazão média/hora (m³/h)";

      case "5":
        return "Pressão média/hora (m.c.a.)";

      case "6":
        return "Corrente média/hora (A)";

      default:
        break;
    }
  };

  const data = {
    labels: labels,
    datasets: [
      {
        fill: true,
        label: handleLabel(),
        data: reading,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        pointBackgroundColor: "rgba(53, 162, 235, 0.5)",
        pointBorderColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const options = {
    resposive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },

      title: {
        display: true,
        text: "Leitura das últimas 24h",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="details-footer p-2">
      <Line data={data} options={options} />
    </div>
  );
};

export default Chart;
