export const getDate = (dateTime) => {
  if (dateTime) {
    const date = dateTime.split(" ")[0];
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
  return null;
};

export const getTime = (dateTime) => {
  if (dateTime) {
    const time = dateTime.split(" ")[1];
    return time;
  }
  return null;
};

export const configurePoint = (pointType, setReadingType, setUnit) => {
  switch (pointType) {
    case "1" || 1:
    case "11" || 11:
      setReadingType(["Nível"]);
      setUnit(["m.c.a."]);
      break;

    case "2" || 2:
      setReadingType(["Bomba"]);
      setUnit("");
      break;

    case "3" || 3:
    case "4" || 4:
    case "9" || 4:
    case "10" || 10:
      setReadingType(["Vazão", "Volume"]);
      setUnit(["m³/h", "m³"]);
      break;

    case "5" || 5:
      setReadingType(["Pressão"]);
      setUnit(["m.c.a."]);
      break;

    case "6" || 6:
      setReadingType([
        "Corrente",
        "Potência",
        "Consumo",
        "Tensão fase 1",
        "Tensão fase 2",
        "Tensão fase 3",
      ]);
      setUnit(["A", "kW", "kW/h", "V", "V", "V"]);
      break;

    case "7" || 7:
      setReadingType(["PH"]);
      setUnit([""]);
      break;

    case "8" || 8:
      setReadingType(["Oxigênio"]);
      setUnit([""]);
      break;

    default:
      break;
  }
};
