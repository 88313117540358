import { Container, Spinner } from "react-bootstrap";

const Modal = ({ isSlowConnection }) => {
  return (
    <Container
      fluid
      className="text-white d-flex flex-column justify-content-center align-items-center modal"
      style={{ backgroundColor: "rgba(0,0,0,.8)" }}
    >
      <Spinner animation="border" variant="white" className="mb-2" />
      <span className="fs-4">Aguarde...</span>
      {isSlowConnection && (
        <span className="fs-4">Conexão lenta no provedor dos dados.</span>
      )}
    </Container>
  );
};

export default Modal;
