import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useAuth } from "../../contexts/auth";
import { useSearch } from "../../contexts/search";
import { getPoints, getAlarms } from "../../services/api";

import Card from "./Card";

const CardList = ({ setLoading, setIsSlowConnection }) => {
  const { logout, setLoginFailed, setLoginFailedMessage } = useAuth();
  const { search } = useSearch();

  const [points, setPoints] = useState([]);
  const [filteredPoints, setFilteredPoints] = useState([]);
  const [interval, setLocalInterval] = useState();

  useEffect(() => {
    handleGetPoints();
    setLocalInterval(setInterval(handleGetPoints, 1000 * 60));
    return () => clearInterval(interval);
  }, []);

  const handleGetPoints = async () => {
    try {
      const response = await getPoints();
      const errorMessage = ["Expired token", "Connection timed out"];

      if (!response || errorMessage.includes(response.data)) {
        setLoginFailed(true);
        setLoginFailedMessage(response.data);
        logout();
      }

      if (response.data.includes("SQLSTATE[HY000] [2002]")) {
        setLoading(false);
        setIsSlowConnection(true);
        setLoading(true);
        return;
      }

      setPoints(response.data);
      setLoading(false);
      await getAlarms();
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    if (error.response.status === 401) {
      const reloadCount = parseInt(localStorage.getItem("reloadCount"), 10);
      localStorage.setItem("reloadCount", reloadCount + 1);
      window.location.reload();

      if (reloadCount > 3) {
        localStorage.setItem("reloadCount", 0);
        logout();
      }
    } else {
      localStorage.setItem("reloadCount", 0);
      //console.log(error);
    }
  };

  useEffect(() => {
    handleFilterPoints();
  }, [search, points]);

  const handleFilterPoints = () => {
    const upperSearch = search.toUpperCase();
    try {
      setFilteredPoints(
        points?.filter((point) => point.name.includes(upperSearch))
      );
    } catch (error) {
      handleFilterError(error);
    }
  };

  const handleFilterError = (error) => {
    //console.log(error);
    window.location.href = "/";
  };

  return (
    <Container>
      <Row className="justify-content-center mb-5">
        <Col md={7} lg={5}>
          {filteredPoints?.map((point) => (
            <Card point={point} key={point.pointId} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default CardList;
