import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { getDetails } from "../../services/api";
import { isDisconnected } from "../../utils/readingInterval";
import {
  getDate,
  getTime,
  configurePoint,
} from "./configurations/detailsConfig";

import Modal from "../../components/Modal";
import Chart from "./Chart";
import Info from "./Info";
import Header from "./Header";

const Details = () => {
  const location = useLocation();
  const point = location.state;
  const pointType = point.pointType;
  const navigate = useNavigate();

  const [readingType, setReadingType] = useState([]);
  const [unit, setUnit] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [lastReading, setLastReading] = useState();
  const [dateTime, setDateTime] = useState();
  const [manualPumpDrive, setManualPumpDrive] = useState();
  const [automaticPumpDrive, setAutomaticPumpDrive] = useState({
    enabled: null,
  });
  const [loading, setLoading] = useState(true);
  const [interval, setLocalInterval] = useState();
  const [disconnected, setDisconnected] = useState(false);

  useEffect(() => {
    configurePoint(pointType, setReadingType, setUnit);
    handleGetDetails();
    setLocalInterval(setInterval(handleGetDetails, 1000 * 30));
    return () => clearInterval(interval);
  }, [point]);

  const handleGetDetails = async () => {
    if (!isDisconnected(point.dateTime)) {
      try {
        const response = await getDetails(
          point.pointId,
          pointType,
          point.dateTime
        );
        handleSetDetails(response.data);
      } catch (error) {
        //console.log(error);
      }
    } else {
      setDateTime(`${getDate(point.dateTime)} ${getTime(point.dateTime)}`);
      setDisconnected(true);
      setTimeout(() => {
        navigate("/");
      }, 1000 * 30);
    }
  };

  const handleSetDetails = (data) => {
    const pumpDrive = data.pumpDrive;
    const lastReading = data.lastReading;

    if (lastReading) {
      const readings = Object.values(lastReading.readings);
      setChartData(data.chartData);
      setDateTime(
        `${getDate(lastReading?.dateTime)} ${getTime(lastReading?.dateTime)}`
      );
      setLastReading(readings);
      setManualPumpDrive(pumpDrive.manual);
      setAutomaticPumpDrive(pumpDrive.automatic);
      point.dateTime = lastReading.dateTime;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading && <Modal />}

      <Container>
        <Row className="justify-content-center">
          <Col
            md={7}
            xl={9}
            className={`border ${
              point.alarm
                ? "border-danger bg-color-alarm"
                : "border-primary bg-color"
            } border-2 rounded p-0 shadow`}
            style={{ height: "99.98vh" }}
          >
            <Header point={point} dateTime={dateTime} />

            <Info
              point={point}
              lastReading={lastReading}
              readingType={readingType}
              unit={unit}
              disconnected={disconnected}
              manualPumpDrive={manualPumpDrive}
              automaticPumpDrive={automaticPumpDrive}
            />

            <Chart chartData={chartData} pointType={pointType} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Details;
